@import (reference) "./common";
@import (reference) "./grid";

#highlights {
  padding-top: 57px;
  padding-bottom: 57px;
  text-align: center;

  @media @sm {
    padding-bottom: 0;
  }

  @media @xl {
    padding-top: 135px;
  }

  ul {
    .nolist(0);
    .flex();
    .column();
    .sm\:row();

    li {
      .sm\:f(6);
      .md\:f(3);
      padding: 100px 0 52px;

      @media @xl {
        padding: 150px 0 110px;
      }

      &:nth-child(1) {
        background: url("../images/highlight-1.svg") center top / 125px no-repeat;

        @media @xl {
          background-size: 174px;
        }
      }

      &:nth-child(2) {
        background: url("../images/highlight-2.svg") center top / 120px no-repeat;

        @media @xl {
          background-size: 167px;
        }
      }

      &:nth-child(3) {
        background: url("../images/highlight-3.svg") center 10px / 103px no-repeat;

        @media @xl {
          background-size: 144px;
        }
      }

      &:nth-child(4) {
        background: url("../images/highlight-4.svg") center top / 108px no-repeat;

        @media @xl {
          background-size: 150px;
        }
      }
    }
  }

  h3 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 31px;
    font-weight: 500;

    @media @lg {
      font-size: 28px;
      line-height: 40px;
    }

    @media @xl {
      margin-bottom: 24px;
      font-size: 36px;
      line-height: 56px;
    }
  }

  p {
    max-width: 260px;
    margin: 0 auto;

    @media @lg {
      font-size: 16px;
      line-height: 25px;
    }

    @media @xl {
      max-width: 400px;
      font-size: 20px;
      line-height: 30px;
    }
  }
}


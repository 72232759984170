@import (reference) "./common";
@import (reference) "./grid";

#intro {
  padding: 30px 0 53px;
  background:
    url("../images/bg-intro.svg") ~"max(0px, 100vw - 360px)" 125px / auto 230px no-repeat,
    linear-gradient(175deg, #fff -500px, #adecf7 250px) 0 0 no-repeat padding-box,
    #adecf7;

  @media @sm {
    padding-top: 40px;
    background-size: auto 280px, auto;
    background-position: ~"max(50vw, 100vw - 440px)" 175px, 0 0;
  }

  @media @lg {
    padding-top: 69px;
  }

  @media (min-width: 1200px) {
    background-position: ~"max(50vw, 100vw - 440px)" 145px, 0 0;
  }

  @media @xl {
    padding-top: 96px;
    background-size: auto 572px, auto;
    background-position: right 280px, 0 0;
  }

  header {
    .flex();
    .row();

    align-items: center;
    padding-right: 24px;

    @media @sm {
      padding-right: calc(50vw - 640px / 2);
    }

    @media @md {
      padding-right: calc(50vw - 960px / 2);
    }

    @media (min-width: 1200px) {
      padding-right: calc(50vw - 1055px / 2);
    }

    @media @lg {
      padding-right: calc(50vw - 1200px / 2);
    }

    @media @xl {
      padding-right: calc(50vw - 1640px / 2);
    }

    h1 {
      position: fixed;
      z-index: 20;

      img {
        width: 179px;
        height: auto;

        @media @xl {
          width: 333px;
        }
      }
    }
  }

  .container {
    .flex();
    .column();

    padding-top: 280px;
    align-items: center;

    @media @sm {
      padding-top: 75px;
      align-items: flex-start;
    }

    @media @xl {
      padding-top: 182px;
      padding-bottom: 100px;
    }

    h2 {
      max-width: 315px;
      margin-bottom: 36px;
      font-size: 32px;
      line-height: 36px;
      font-weight: bold;

      @media @sm {
        max-width: min(40vw, 580px);
        font-size: 42px;
        line-height: 48px;
      }

      @media @lg {
        font-size: 52px;
        line-height: 60px;
      }

      @media @xl {
        max-width: calc(1640px / 2);
        font-size: 72px;
        line-height: 90px;
      }
    }

    a {
      .button();
      background: #005cb9;
      color: #fff;

      @media @sm {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

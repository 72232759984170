@import (reference) "./grid";

#btn-toggle-menu {
  display: block;
  width: 25px;
  height: 21px;
  margin: 0 0 0 auto;
  padding: 0;
  border: none;
  background: url("../images/icons/menu.svg") center center no-repeat;
  outline: none;
  cursor: pointer;

  @media @sm {
    display: none;
  }
}

#menu {
  position: fixed;
  left: 0;
  top: -100vh;
  z-index: 30;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  transition: top 0.3s step-end, background-color 0.3s;

  @media @sm {
    position: static;
    left: auto;
    top: auto;
    width: auto;
    height: auto;
    margin-left: auto;
    overflow: visible;
    .fmin();
  }

  ul {
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-align: right;
    background: #fff;
    transform: translateX(100%);
    transition: transform 0.3s;

    @media @sm {
      background: none;
      transform: none;
    }

    a {
      display: block;
    }
  }

  &.open {
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: top 0.3s step-start, background-color 0.3s;

    ul {
      transform: translateX(0);
    }
  }

  .menu-main {
    position: relative;
    margin: 0;
    padding: 60px 30px 20px;
    background: #225DB3;

    &::before {
      position: absolute;
      left: 36px;
      bottom: 0;
      width: 28px;
      height: 28px;
      border: 14px solid rgba(0,0,0,0);
      border-bottom-color: #fff;
      content: '';
    }

    @media @sm {
      padding: 0;
      background: none;

      &::before {
        display: none;
      }
    }

    a {
      padding-right: 30px;
      color: #fff;
      line-height: 32px;
      text-decoration: none;
      background: url("../images/icons/user-w.svg") right center / 18px no-repeat;

      @media @sm {
        padding-right: 23px;
        color: #000;
        text-decoration: underline;
        background: url("../images/icons/user.svg") right center / 18px no-repeat;
      }

      @media @lg {
        padding-right: 25px;
        font-size: 20px;
        background-size: 20px;
      }

      @media @xl {
        padding-right: 27px;
        font-size: 22px;
        background-size: 22px;
      }
    }
  }

  .menu-link {
    width: 210px;
    margin-left: 40px;
    padding: 20px 35px;
    border-bottom: 1px solid #E2EDFA;

    a {
      padding-right: 25px;
      color: #000000;
      text-decoration: none;
      background: url("../images/icons/caret.svg") right center / 7px no-repeat;
    }

    @media @sm {
      display: none;
    }
  }
}

@font-face {
  font-family: DM_Sans;
  src: url("fonts/dmsans-regular.2492570d.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  src: url("fonts/dmsans-italic.79eb8ca7.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  src: url("fonts/dmsans-medium.df74d284.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  src: url("fonts/dmsans-mediumitalic.d62d19d6.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  src: url("fonts/dmsans-bold.2359b7a4.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  src: url("fonts/dmsans-bolditalic.c4f180ed.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

.container {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1055px;
  }
}

@media (min-width: 1366px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1640px;
  }
}

.cs0 {
  grid-column-start: 1;
}

.rs0 {
  grid-row-start: 1;
}

.rs1 {
  grid-row-start: 2;
}

.cs6 {
  grid-column-start: 7;
}

.ce6 {
  grid-column-end: 7;
}

.rs6 {
  grid-row-start: 7;
}

.re6 {
  grid-row-end: 7;
}

.rs8 {
  grid-row-start: 9;
}

.re8 {
  grid-row-end: 9;
}

.ce12 {
  grid-column-end: 13;
}

.re12 {
  grid-row-end: 13;
}

*, :before, :after {
  box-sizing: border-box;
}

body, html {
  width: 100vw;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  color: #707070;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  font: 14px / 22px DM_Sans, sans-serif;
}

h1, h2, h3, h4 {
  color: #000;
  margin: 0;
  font-weight: bold;
}

h2 {
  font-size: 32px;
  line-height: 42px;
}

@media (min-width: 1024px) {
  h2 {
    font-size: 42px;
    line-height: 56px;
  }
}

h3, h4 {
  font-size: 28px;
  line-height: 32px;
}

@media (min-width: 1024px) {
  h3 {
    font-size: 30px;
    line-height: 38px;
  }

  h4 {
    font-size: 29px;
    line-height: 36px;
  }
}

@media (min-width: 1366px) {
  h3 {
    font-size: 32px;
    line-height: 42px;
  }

  h4 {
    font-size: 30px;
    line-height: 39px;
  }
}

@media (min-width: 1920px) {
  h3 {
    font-size: 50px;
    line-height: 65px;
  }
}

p {
  margin: 0 0 20px;
}

p.byline {
  font-size: 18px;
  line-height: 30px;
}

@media (min-width: 768px) {
  p.byline {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  p.byline {
    font-size: 22px;
  }
}

@media (min-width: 1920px) {
  p.byline {
    font-size: 32px;
    line-height: 42px;
  }
}

a {
  color: inherit;
  text-decoration: underline;
}

img[height] {
  height: auto;
}

.container {
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 768px) {
  .sm\:hidden {
    display: none;
  }
}

#intro {
  background: url("images/bg-intro.196f1178.svg") max(0px, 100vw - 360px) 125px / auto 230px no-repeat, linear-gradient(175deg, #fff -500px, #adecf7 250px) no-repeat padding-box padding-box, #adecf7;
  padding: 30px 0 53px;
}

@media (min-width: 768px) {
  #intro {
    background-position: max(50vw, 100vw - 440px) 175px, 0 0;
    background-size: auto 280px, auto;
    padding-top: 40px;
  }
}

@media (min-width: 1366px) {
  #intro {
    padding-top: 69px;
  }
}

@media (min-width: 1200px) {
  #intro {
    background-position: max(50vw, 100vw - 440px) 145px, 0 0;
  }
}

@media (min-width: 1920px) {
  #intro {
    background-position: right 280px, 0 0;
    background-size: auto 572px, auto;
    padding-top: 96px;
  }
}

#intro header {
  flex-flow: wrap;
  align-items: center;
  padding-right: 24px;
  display: flex;
}

@media (min-width: 768px) {
  #intro header {
    padding-right: calc(50vw - 320px);
  }
}

@media (min-width: 1024px) {
  #intro header {
    padding-right: calc(50vw - 480px);
  }
}

@media (min-width: 1200px) {
  #intro header {
    padding-right: calc(50vw - 527.5px);
  }
}

@media (min-width: 1366px) {
  #intro header {
    padding-right: calc(50vw - 600px);
  }
}

@media (min-width: 1920px) {
  #intro header {
    padding-right: calc(50vw - 820px);
  }
}

#intro header h1 {
  z-index: 20;
  position: fixed;
}

#intro header h1 img {
  width: 179px;
  height: auto;
}

@media (min-width: 1920px) {
  #intro header h1 img {
    width: 333px;
  }
}

#intro .container {
  flex-flow: column wrap;
  align-items: center;
  padding-top: 280px;
  display: flex;
}

@media (min-width: 768px) {
  #intro .container {
    align-items: flex-start;
    padding-top: 75px;
  }
}

@media (min-width: 1920px) {
  #intro .container {
    padding-top: 182px;
    padding-bottom: 100px;
  }
}

#intro .container h2 {
  max-width: 315px;
  margin-bottom: 36px;
  font-size: 32px;
  font-weight: bold;
  line-height: 36px;
}

@media (min-width: 768px) {
  #intro .container h2 {
    max-width: min(40vw, 580px);
    font-size: 42px;
    line-height: 48px;
  }
}

@media (min-width: 1366px) {
  #intro .container h2 {
    font-size: 52px;
    line-height: 60px;
  }
}

@media (min-width: 1920px) {
  #intro .container h2 {
    max-width: 820px;
    font-size: 72px;
    line-height: 90px;
  }
}

#intro .container a {
  color: #fff;
  background: #005cb9;
  border: 1px solid #0000;
  border-radius: 40px;
  margin: 0 auto;
  padding: 11px 32px;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  display: block;
}

@media (min-width: 1920px) {
  #intro .container a {
    padding: 20px 60px;
    font-size: 32px;
    line-height: 42px;
  }
}

@media (min-width: 768px) {
  #intro .container a {
    margin-left: 0;
    margin-right: 0;
  }
}

#btn-toggle-menu {
  width: 25px;
  height: 21px;
  cursor: pointer;
  background: url("images/icons/menu.1cab0e59.svg") center no-repeat;
  border: none;
  outline: none;
  margin: 0 0 0 auto;
  padding: 0;
  display: block;
}

@media (min-width: 768px) {
  #btn-toggle-menu {
    display: none;
  }
}

#menu {
  z-index: 30;
  width: 100vw;
  height: 100vh;
  background: none;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  transition: top .3s step-end, background-color .3s;
  display: flex;
  position: fixed;
  top: -100vh;
  left: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  #menu {
    width: auto;
    height: auto;
    flex: none;
    margin-left: auto;
    position: static;
    top: auto;
    left: auto;
    overflow: visible;
  }
}

#menu ul {
  height: 100%;
  text-align: right;
  background: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  list-style: none;
  transition: transform .3s;
  transform: translateX(100%);
}

@media (min-width: 768px) {
  #menu ul {
    background: none;
    transform: none;
  }
}

#menu ul a {
  display: block;
}

#menu.open {
  background: #0006;
  transition: top .3s step-start, background-color .3s;
  top: 0;
}

#menu.open ul {
  transform: translateX(0);
}

#menu .menu-main {
  background: #225db3;
  margin: 0;
  padding: 60px 30px 20px;
  position: relative;
}

#menu .menu-main:before {
  width: 28px;
  height: 28px;
  content: "";
  border: 14px solid #0000;
  border-bottom-color: #fff;
  position: absolute;
  bottom: 0;
  left: 36px;
}

@media (min-width: 768px) {
  #menu .menu-main {
    background: none;
    padding: 0;
  }

  #menu .menu-main:before {
    display: none;
  }
}

#menu .menu-main a {
  color: #fff;
  background: url("images/icons/user-w.fadca979.svg") 100% / 18px no-repeat;
  padding-right: 30px;
  line-height: 32px;
  text-decoration: none;
}

@media (min-width: 768px) {
  #menu .menu-main a {
    color: #000;
    background: url("images/icons/user.d40b08cb.svg") 100% / 18px no-repeat;
    padding-right: 23px;
    text-decoration: underline;
  }
}

@media (min-width: 1366px) {
  #menu .menu-main a {
    background-size: 20px;
    padding-right: 25px;
    font-size: 20px;
  }
}

@media (min-width: 1920px) {
  #menu .menu-main a {
    background-size: 22px;
    padding-right: 27px;
    font-size: 22px;
  }
}

#menu .menu-link {
  width: 210px;
  border-bottom: 1px solid #e2edfa;
  margin-left: 40px;
  padding: 20px 35px;
}

#menu .menu-link a {
  color: #000;
  background: url("images/icons/caret.ccd9b72a.svg") 100% / 7px no-repeat;
  padding-right: 25px;
  text-decoration: none;
}

@media (min-width: 768px) {
  #menu .menu-link {
    display: none;
  }
}

#highlights {
  text-align: center;
  padding-top: 57px;
  padding-bottom: 57px;
}

@media (min-width: 768px) {
  #highlights {
    padding-bottom: 0;
  }
}

@media (min-width: 1920px) {
  #highlights {
    padding-top: 135px;
  }
}

#highlights ul {
  flex-flow: column wrap;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
}

#highlights ul li {
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  #highlights ul {
    flex-flow: wrap;
  }
}

#highlights ul li {
  padding: 100px 0 52px;
}

@media (min-width: 768px) {
  #highlights ul li {
    flex: 0 0 50%;
  }
}

@media (min-width: 1024px) {
  #highlights ul li {
    flex: 0 0 25%;
  }
}

@media (min-width: 1920px) {
  #highlights ul li {
    padding: 150px 0 110px;
  }
}

#highlights ul li:nth-child(1) {
  background: url("images/highlight-1.019b7122.svg") top / 125px no-repeat;
}

@media (min-width: 1920px) {
  #highlights ul li:nth-child(1) {
    background-size: 174px;
  }
}

#highlights ul li:nth-child(2) {
  background: url("images/highlight-2.4638a01a.svg") top / 120px no-repeat;
}

@media (min-width: 1920px) {
  #highlights ul li:nth-child(2) {
    background-size: 167px;
  }
}

#highlights ul li:nth-child(3) {
  background: url("images/highlight-3.d37d822d.svg") 50% 10px / 103px no-repeat;
}

@media (min-width: 1920px) {
  #highlights ul li:nth-child(3) {
    background-size: 144px;
  }
}

#highlights ul li:nth-child(4) {
  background: url("images/highlight-4.70f1af53.svg") top / 108px no-repeat;
}

@media (min-width: 1920px) {
  #highlights ul li:nth-child(4) {
    background-size: 150px;
  }
}

#highlights h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
}

@media (min-width: 1366px) {
  #highlights h3 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media (min-width: 1920px) {
  #highlights h3 {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 56px;
  }
}

#highlights p {
  max-width: 260px;
  margin: 0 auto;
}

@media (min-width: 1366px) {
  #highlights p {
    font-size: 16px;
    line-height: 25px;
  }
}

@media (min-width: 1920px) {
  #highlights p {
    max-width: 400px;
    font-size: 20px;
    line-height: 30px;
  }
}

#projects {
  color: #fff;
  background: #343538 linear-gradient(174deg, #2186d8 -540px, #343538 300px 100%) no-repeat padding-box padding-box;
  padding: 40px 0;
}

@media (min-width: 1024px) {
  #projects {
    padding-bottom: 50px;
  }
}

@media (min-width: 1920px) {
  #projects {
    padding-top: 105px;
    padding-bottom: 100px;
  }
}

#projects .container {
  flex-flow: column wrap;
  display: flex;
}

#projects h2 {
  color: #adecf7;
  margin-bottom: 22px;
  line-height: 36px;
}

@media (min-width: 1920px) {
  #projects h2 {
    font-size: 72px;
    line-height: 93px;
  }
}

#projects p.byline {
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 768px) {
  #projects p.byline {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (min-width: 1920px) {
  #projects p.byline {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 39px;
  }
}

#projects .project {
  flex-flow: column wrap;
  margin: 22px 0;
  display: flex;
}

@media (min-width: 1024px) {
  #projects .project {
    flex-flow: wrap;
  }
}

@media (min-width: 1920px) {
  #projects .project {
    margin: 45px 0;
  }
}

#projects .project .project-description {
  order: 1;
}

@media (min-width: 1024px) {
  #projects .project .project-description {
    flex: 0 0 33.3333%;
  }
}

@media (min-width: 1920px) {
  #projects .project .project-description {
    font-size: 24px;
    line-height: 40px;
  }
}

#projects .project .project-description h3, #projects .project .project-description h4 {
  color: #e2edfa;
  margin: 12px 0;
}

#projects .project .project-description h4 {
  color: #adecf7;
}

#projects .project .project-description p + h4 {
  margin-top: 32px;
}

#projects .project .project-description a {
  color: #adecf7;
}

#projects .project .project-description ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

#projects .project .project-description ul li {
  margin: 0;
  padding: 0;
}

#projects .project .project-description ul li:before {
  content: ">";
  margin-right: 1em;
  display: inline-block;
}

#projects .project .project-gallery {
  order: 0;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -3px;
}

@media (min-width: 1024px) {
  #projects .project .project-gallery {
    flex: 0 0 66.6667%;
  }
}

@media (min-width: 1024px) {
  #projects .project .project-gallery {
    margin: 0;
    padding-right: 40px;
  }
}

@media (min-width: 1920px) {
  #projects .project .project-gallery {
    padding-right: 54px;
  }
}

#projects .project .project-gallery ul {
  grid-template-rows: repeat(12, auto);
  grid-template-columns: repeat(12, auto);
  margin: 0 -5px 15px;
  padding: 0;
  list-style: none;
  display: grid;
}

#projects .project .project-gallery ul li {
  margin: 0;
  padding: 0;
}

@media (min-width: 1920px) {
  #projects .project .project-gallery ul {
    margin: 0 -25px 15px;
  }
}

#projects .project .project-gallery ul li {
  padding: 0 5px 17px;
}

@media (min-width: 1920px) {
  #projects .project .project-gallery ul li {
    padding: 0 25px 50px;
  }
}

#projects .project .project-gallery ul li img {
  width: 100%;
  display: block;
}

@media (min-width: 1024px) {
  #projects .project:nth-child(2n) .project-gallery {
    order: 2;
    padding-left: 40px;
    padding-right: 0;
  }
}

#services {
  background: linear-gradient(162deg, #ffffff40 -500px, #adecf740 100px) no-repeat padding-box padding-box;
  padding-top: 51px;
  padding-bottom: 100px;
  position: relative;
}

@media (min-width: 1024px) {
  #services {
    background: url("images/bg-services.a37ee92d.svg") right 30px no-repeat, linear-gradient(162deg, #ffffff40 -500px, #adecf740 100px) no-repeat padding-box padding-box;
  }
}

@media (min-width: 1920px) {
  #services {
    background-size: 280px, auto;
    padding-top: 121px;
    padding-bottom: 120px;
  }
}

#services h2 {
  margin: 0 0 12px;
  font-size: 32px;
  line-height: 42px;
}

@media (min-width: 1920px) {
  #services h2 {
    margin-bottom: 34px;
    font-size: 72px;
    line-height: 90px;
  }
}

@media (min-width: 1920px) {
  #services .byline {
    margin-bottom: 63px;
  }
}

#services ul {
  background: #fff;
  border: 1px solid #e2edfa;
  flex-flow: column wrap;
  margin: 0 -3px;
  padding: 0;
  list-style: none;
  display: flex;
}

#services ul li {
  margin: 0;
  padding: 0;
}

@media (min-width: 1024px) {
  #services ul {
    flex-flow: wrap;
  }
}

#services ul li {
  margin: 0;
  padding: 11px;
}

@media (min-width: 1024px) {
  #services ul li {
    flex: 0 0 33.3333%;
  }
}

@media (min-width: 1024px) {
  #services ul li {
    padding: 12px 22px;
  }
}

@media (min-width: 1920px) {
  #services ul li {
    padding: 30px;
  }
}

#services ul li + li {
  border-top: 1px solid #e2edfa;
}

@media (min-width: 1024px) {
  #services ul li + li {
    border-top: none;
    border-left: 1px solid #e2edfa;
  }
}

#services ul li h3 {
  margin: 0 0 8px;
  font-size: 22px;
  line-height: 29px;
}

@media (min-width: 1920px) {
  #services ul li h3 {
    font-size: 30px;
    line-height: 39px;
  }
}

#services ul li p {
  line-height: 18px;
}

@media (min-width: 1920px) {
  #services ul li p {
    font-size: 18px;
    line-height: 24px;
  }
}

#support {
  background: url("images/support.d856941e.svg") 50% 37px / 197px no-repeat;
  padding-top: 245px;
  padding-bottom: 32px;
}

@media (min-width: 1024px) {
  #support {
    background-position: calc(50vw - 520px) 0;
    background-size: 337px;
    padding-top: 130px;
    padding-bottom: 82px;
  }
}

@media (min-width: 1200px) {
  #support {
    background-position: calc(50vw - 527.5px) 0;
  }
}

@media (min-width: 1366px) {
  #support {
    background-position: calc(50vw - 600px) 0;
  }
}

@media (min-width: 1920px) {
  #support {
    background-position: calc(50vw - 820px) 0;
    background-size: 494px;
    padding-top: 190px;
    padding-bottom: 150px;
  }
}

#support .container {
  flex-flow: wrap;
  display: flex;
}

@media (min-width: 1024px) {
  #support .container {
    justify-content: flex-end;
  }
}

#support h2 {
  flex: 0 0 100%;
  margin: 0 0 12px;
}

@media (min-width: 1024px) {
  #support h2 {
    flex: 0 0 66.6667%;
  }
}

@media (min-width: 1920px) {
  #support h2 {
    margin-bottom: 40px;
    font-size: 72px;
    line-height: 90px;
  }
}

#support p {
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  #support p {
    flex: 0 0 66.6667%;
  }
}

@media (min-width: 1024px) {
  #support p {
    font-size: 20px;
  }
}

@media (min-width: 1920px) {
  #support p {
    line-height: 30px;
  }
}

#references {
  padding-bottom: 56px;
}

#references h2 {
  margin: 0 0 20px;
}

@media (min-width: 1920px) {
  #references h2 {
    margin-bottom: 49px;
    font-size: 72px;
    line-height: 90px;
  }
}

#references ul {
  flex-flow: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

#references ul li {
  border: 1px solid #b6eef8;
  flex: 0 0 50%;
  margin: 0;
  padding: 20px;
}

@media (min-width: 768px) {
  #references ul li {
    flex: 0 0 33.3333%;
  }
}

@media (min-width: 1024px) {
  #references ul li {
    flex: 0 0 16.6667%;
  }
}

#references ul li:before {
  float: left;
  width: 0;
  height: 0;
  content: "";
  padding-bottom: 100%;
  display: block;
}

#references ul li a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
}

#references ul li a img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: auto;
}

#contact .contact-summary {
  background: linear-gradient(162deg, #ffffff40 -500px, #adecf740 100px) no-repeat padding-box padding-box;
  flex-flow: column wrap;
  align-items: stretch;
  display: flex;
}

@media (min-width: 1024px) {
  #contact .contact-summary {
    flex-flow: wrap;
  }
}

#contact .contact-summary .container {
  padding: 50px 22px 20px;
}

@media (min-width: 1024px) {
  #contact .contact-summary .container {
    max-width: 480px;
    margin-right: 0;
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  #contact .contact-summary .container {
    max-width: 527.5px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media (min-width: 1366px) {
  #contact .contact-summary .container {
    max-width: 600px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1920px) {
  #contact .contact-summary .container {
    max-width: 820px;
    padding-top: 90px;
    padding-bottom: 100px;
    font-size: 24px;
    line-height: 34px;
  }
}

#contact .contact-summary .container h2 {
  margin-bottom: 20px;
}

@media (min-width: 1920px) {
  #contact .contact-summary .container h2 {
    margin-bottom: 90px;
    font-size: 72px;
    line-height: 90px;
  }
}

#contact .contact-summary .container dl {
  color: #000;
  margin: 0;
  padding: 0;
  list-style: none;
}

#contact .contact-summary .container dl dt, #contact .contact-summary .container dl dd {
  margin: 0;
  padding: 0 0 0 30px;
}

#contact .contact-summary .container dl dt {
  font-weight: bold;
  position: relative;
}

@media (min-width: 1920px) {
  #contact .contact-summary .container dl dt {
    margin-bottom: 12px;
  }
}

#contact .contact-summary .container dl dt img {
  width: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (min-width: 1024px) {
  #contact .contact-summary .container dl dt img {
    width: 18px;
  }
}

@media (min-width: 1200px) {
  #contact .contact-summary .container dl dt img {
    width: 19px;
  }
}

@media (min-width: 1366px) {
  #contact .contact-summary .container dl dt img {
    width: 20px;
  }
}

@media (min-width: 1920px) {
  #contact .contact-summary .container dl dt img {
    width: 22px;
  }
}

#contact .contact-summary .container dl dd {
  margin-bottom: 20px;
}

@media (min-width: 1920px) {
  #contact .contact-summary .container dl dd {
    margin-bottom: 30px;
  }
}

#contact .contact-summary iframe {
  width: 100%;
  height: 340px;
  border: none;
  display: block;
}

@media (min-width: 1024px) {
  #contact .contact-summary iframe {
    width: 50vw;
    height: auto;
  }
}

#contact .contact-details {
  padding: 25px 0 0;
}

@media (min-width: 1920px) {
  #contact .contact-details {
    padding-top: 120px;
  }
}

#contact .contact-details .container {
  flex-flow: column wrap;
  display: flex;
}

@media (min-width: 768px) {
  #contact .contact-details .container {
    flex-flow: wrap;
  }
}

@media (min-width: 768px) {
  #contact .contact-details .container > div {
    flex: 0 0 50%;
  }
}

@media (min-width: 1024px) {
  #contact .contact-details .container > div {
    flex: 0 0 25%;
  }
}

#contact .contact-details .container h3 {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}

#contact .contact-details .container p {
  line-height: 24px;
}

@media (min-width: 1024px) {
  #contact .contact-details .container h3 {
    font-size: 18px;
  }
}

@media (min-width: 1366px) {
  #contact .contact-details .container h3 {
    font-size: 20px;
  }

  #contact .contact-details .container p {
    font-size: 15px;
  }
}

@media (min-width: 1920px) {
  #contact .contact-details .container h3 {
    font-size: 24px;
    line-height: 30px;
  }

  #contact .contact-details .container p {
    font-size: 18px;
    line-height: 24px;
  }
}

footer {
  padding: 28px 0 40px;
}

@media (min-width: 1920px) {
  footer {
    padding: 60px 0 120px;
    font-size: 18px;
    line-height: 24px;
  }
}

/*# sourceMappingURL=index.202e82ec.css.map */

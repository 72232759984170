@import (reference) "./grid";

footer {
  padding: 28px 0 40px;

  @media @xl {
    padding: 60px 0 120px;
    font-size: 18px;
    line-height: 24px;
  }
}

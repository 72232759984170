@import (reference) "./grid";

*, *::before, *::after {
  box-sizing: border-box;
}

body,
html {
  width: 100vw;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  background: #fff;
  font: 14px/22px "DM_Sans", sans-serif;
  color: #707070;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4 {
  margin: 0;
  font-weight: bold;
  color: #000;
}

h2 {
  font-size: 32px;
  line-height: 42px;

  @media @md {
    font-size: 42px;
    line-height: 56px;
  }
}

h3, h4 {
  font-size: 28px;
  line-height: 32px;
}

@media @md {
  h3 {
    font-size: 30px;
    line-height: 38px;
  }

  h4 {
    font-size: 29px;
    line-height: 36px;
  }
}

@media @lg {
  h3 {
    font-size: 32px;
    line-height: 42px;
  }

  h4 {
    font-size: 30px;
    line-height: 39px;
  }
}

@media @xl {
  h3 {
    font-size: 50px;
    line-height: 65px;
  }
}

p {
  margin: 0 0 20px;

  &.byline {
    font-size: 18px;
    line-height: 30px;

    @media @sm {
      font-size: 20px;
    }

    @media @md {
      font-size: 22px;
    }

    @media @xl {
      font-size: 32px;
      line-height: 42px;
    }
  }
}

a {
  color: inherit;
  text-decoration: underline;
}

img[height] {
  height: auto;
}

.nolist(@p: true) {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

.nolist(@p: true) when (@p) {
  padding: 0;
}

.container {
  padding-left: 22px;
  padding-right: 22px;
}

.button() {
  display: block;
  margin: 0 auto;
  padding: 11px 32px;
  border: 1px solid transparent;
  border-radius: 40px;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;

  @media @xl {
    padding: 20px 60px;
    font-size: 32px;
    line-height: 42px;
  }
}

.hidden { display: none; }
.sm\:hidden { @media @sm { display: none; } }
.md\:hidden { @media @md { display: none; } }
.lg\:hidden { @media @lg { display: none; } }

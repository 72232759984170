@import (reference) "./common";
@import (reference) "./grid";

#contact {
  .contact-summary {
    background: linear-gradient(162deg, rgb(255 255 255 / 25%) -500px, rgb(173 236 247 / 25%) 100px) 0 0 no-repeat padding-box;

    .flex();
    .column();
    .md\:row();
    align-items: stretch;

    .container {
      padding: 50px 22px 20px;

      @media @md {
        max-width: calc(960px / 2);
        margin-right: 0;
        font-size: 16px;
      }

      @media (min-width: 1200px) {
        max-width: calc(1055px / 2);
        font-size: 18px;
        line-height: 24px;
      }

      @media @lg {
        max-width: calc(1200px / 2);
        font-size: 20px;
        line-height: 28px;
      }

      @media @xl {
        padding-top: 90px;
        padding-bottom: 100px;
        max-width: calc(1640px / 2);
        font-size: 24px;
        line-height: 34px;
      }

      h2 {
        margin-bottom: 20px;

        @media @xl {
          font-size: 72px;
          line-height: 90px;
          margin-bottom: 90px;
        }
      }

      dl {
        list-style: none;
        margin: 0;
        padding: 0;
        color: #000;

        dt, dd {
          margin: 0;
          padding: 0 0 0 30px;
        }

        dt {
          position: relative;
          font-weight: bold;

          @media @xl {
            margin-bottom: 12px;
          }

          img {
            position: absolute;
            left: 0;
            top: 50%;
            width: 16px;
            transform: translateY(-50%);

            @media @md {
              width: 18px;
            }

            @media (min-width: 1200px) {
              width: 19px;
            }

            @media @lg {
              width: 20px;
            }

            @media @xl {
              width: 22px;
            }
          }
        }

        dd {
          margin-bottom: 20px;

          @media @xl {
            margin-bottom: 30px;
          }
        }
      }
    }

    iframe {
      display: block;
      width: 100%;
      height: 340px;
      border: none;

      @media @md {
        width: 50vw;
        height: auto;
      }
    }
  }

  .contact-details {
    padding: 25px 0 0;

    @media @xl {
      padding-top: 120px;
    }

    .container {
      .flex();
      .column();
      .sm\:row();

      & > div {
        .sm\:f(6);
        .md\:f(3);
      }

      h3 {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
      }

      p {
        line-height: 24px;
      }

      @media @md {
        h3 { font-size: 18px; }
      }

      @media @lg {
        h3 { font-size: 20px; }
        p { font-size: 15px; }
      }

      @media @xl {
        h3 { font-size: 24px; line-height: 30px; }
        p { font-size: 18px; line-height: 24px; }
      }
    }
  }
}

@import (reference) "./grid";

#support {
  padding-top: 245px;
  padding-bottom: 32px;
  background: url("../images/support.svg") center 37px / 197px no-repeat;

  @media @md {
    padding-top: 130px;
    padding-bottom: 82px;
    background-position: calc(50vw - 960px / 2 - 40px) 0;
    background-size: 337px;
  }

  @media (min-width: 1200px) {
    background-position: calc(50vw - 1055px / 2) 0;
  }

  @media @lg {
    background-position: calc(50vw - 1200px / 2) 0;
  }

  @media @xl {
    padding-top: 190px;
    padding-bottom: 150px;
    background-position: calc(50vw - 1640px / 2) 0;
    background-size: 494px;
  }

  .container {
    .flex();
    .row();

    @media @md {
      justify-content: flex-end;
    }
  }

  h2 {
    .f(12);
    .md\:f(8);
    margin: 0 0 12px;

    @media @xl {
      margin-bottom: 40px;
      font-size: 72px;
      line-height: 90px;
    }
  }

  p {
    .f(12);
    .md\:f(8);

    @media @md {
      font-size: 20px;
    }

    @media @xl {
      line-height: 30px;
    }
  }
}

@font-face {
  font-family: 'DM_Sans';
  src: url('dmsans-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM_Sans';
  src: url('dmsans-italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM_Sans';
  src: url('dmsans-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM_Sans';
  src: url('dmsans-mediumitalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'DM_Sans';
  src: url('dmsans-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM_Sans';
  src: url('dmsans-bolditalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

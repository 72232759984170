@import (reference) "./common";
@import (reference) "./grid";

#references {
  padding-bottom: 56px;

  h2 {
    margin: 0 0 20px;

    @media @xl {
      margin-bottom: 49px;
      font-size: 72px;
      line-height: 90px;
    }
  }

  ul {
    .nolist();
    .flex();
    .row();
    margin: 0;
    padding: 0;

    li {
      .f(6);
      .sm\:f(4);
      .md\:f(2);

      margin: 0;
      padding: 20px;
      border: 1px solid #b6eef8;

      &::before {
        display: block;
        float: left;
        width: 0;
        height: 0;
        padding-bottom: 100%;
        content: '';
      }

      a {
        display: flex;
        width: 100%;
        height: 100%;
        text-decoration: none;

        img {
          max-width: 100%;
          width: 100%;
          height: auto;
          margin: auto;
        }
      }
    }
  }
}

@import (reference) "./common";
@import (reference) "./grid";

#services {
  position: relative;
  padding-top: 51px;
  padding-bottom: 100px;
  background: linear-gradient(162deg, rgb(255 255 255 / 25%) -500px, rgb(173 236 247 / 25%) 100px) 0 0 no-repeat padding-box;

  @media @md {
    background:
      url("../images/bg-services.svg") right 30px no-repeat,
      linear-gradient(162deg, rgb(255 255 255 / 25%) -500px, rgb(173 236 247 / 25%) 100px) 0 0 no-repeat padding-box;
  }

  @media @xl {
    padding-top: 121px;
    padding-bottom: 120px;
    background-size: 280px, auto;
  }

  h2 {
    margin: 0 0 12px;
    font-size: 32px;
    line-height: 42px;

    @media @xl {
      margin-bottom: 34px;
      font-size: 72px;
      line-height: 90px;
    }
  }

  .byline {
    @media @xl {
      margin-bottom: 63px;
    }
  }

  ul {
    .nolist();
    .flex();
    .column();
    .md\:row();
    margin: 0 -3px;
    padding: 0;
    background: #fff;
    border: 1px solid #e2edfa;

    li {
      .md\:f(4);
      margin: 0;
      padding: 11px;

      @media @md {
        padding: 12px 22px;
      }

      @media @xl {
        padding: 30px;
      }

      & + li {
        border-top: 1px solid #e2edfa;

        @media @md {
          border-top: none;
          border-left: 1px solid #e2edfa;
        }
      }

      h3 {
        margin: 0 0 8px;
        font-size: 22px;
        line-height: 29px;

        @media @xl {
          font-size: 30px;
          line-height: 39px;
        }
      }

      p {
        line-height: 18px;

        @media @xl {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

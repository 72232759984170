@sm: ~"(min-width: 768px)";
@md: ~"(min-width: 1024px)";
@lg: ~"(min-width: 1366px)";
@xl: ~"(min-width: 1920px)";

.container {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;

  @media @sm {
    max-width: 640px;
  }

  @media @md {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1055px;
  }

  @media @lg {
    max-width: 1200px;
  }

  @media @xl {
    max-width: 1640px;
  }
}

.flex {
  display: flex;
}

.column { flex-flow: column wrap; }
.sm\:column { @media @sm { flex-flow: column wrap; } }
.md\:column { @media @md { flex-flow: column wrap; } }
.lg\:column { @media @lg { flex-flow: column wrap; } }

.row { flex-flow: row wrap; }
.sm\:row { @media @sm { flex-flow: row wrap; } }
.md\:row { @media @md { flex-flow: row wrap; } }
.lg\:row { @media @lg { flex-flow: row wrap; } }

.fmax { flex: 1 1 auto; }
.sm\:fmax { @media @sm { flex: 1 1 auto; } }
.md\:fmax { @media @md { flex: 1 1 auto; } }
.lg\:fmax { @media @lg { flex: 1 1 auto; } }

.fmin { flex: 0 0 auto; }
.sm\:fmin { @media @sm { flex: 0 0 auto; } }
.md\:fmin { @media @md { flex: 0 0 auto; } }
.lg\:fmin { @media @lg { flex: 0 0 auto; } }

.f(@d) { flex: 0 0 percentage((@d / 12)); }
.sm\:f(@d) { @media @sm { flex: 0 0 percentage((@d / 12)); } }
.md\:f(@d) { @media @md { flex: 0 0 percentage((@d / 12)); } }
.lg\:f(@d) { @media @lg { flex: 0 0 percentage((@d / 12)); } }

each(range(1, 12), {
  .f@{value} { flex: 0 0 percentage((@value / 12)); }
  .sm\:f@{value} { @media @sm { flex: 0 0 percentage((@value / 12)); } }
  .md\:f@{value} { @media @md { flex: 0 0 percentage((@value / 12)); } }
  .lg\:f@{value} { @media @lg { flex: 0 0 percentage((@value / 12)); } }
});

.grid(@rows: 12, @cols: 12) {
  display: grid;
  grid-template-rows: repeat(@rows, auto);
  grid-template-columns: repeat(@cols, auto);
}

.cs(@v) { grid-column-start: @v; }
.sm\:cs(@v) { @media @sm { grid-column-start: @v; } }
.md\:cs(@v) { @media @md { grid-column-start: @v; } }
.lg\:cs(@v) { @media @lg { grid-column-start: @v; } }
.ce(@v) { grid-column-end: @v; }
.sm\:ce(@v) { @media @sm { grid-column-end: @v; } }
.md\:ce(@v) { @media @md { grid-column-end: @v; } }
.lg\:ce(@v) { @media @lg { grid-column-end: @v; } }
.rs(@v) { grid-row-start: @v; }
.sm\:rs(@v) { @media @sm { grid-row-start: @v; } }
.md\:rs(@v) { @media @md { grid-row-start: @v; } }
.lg\:rs(@v) { @media @lg { grid-row-start: @v; } }
.re(@v) { grid-row-end: @v; }
.sm\:re(@v) { @media @sm { grid-row-end: @v; } }
.md\:re(@v) { @media @md { grid-row-end: @v; } }
.lg\:re(@v) { @media @lg { grid-row-end: @v; } }

each(range(0, 12), {
  .cs@{value} { grid-column-start: @value + 1; }
  .sm\:cs@{value} { @media @sm { grid-column-start: @value + 1; } }
  .md\:cs@{value} { @media @md { grid-column-start: @value + 1; } }
  .lg\:cs@{value} { @media @lg { grid-column-start: @value + 1; } }
  .ce@{value} { grid-column-end: @value + 1; }
  .sm\:ce@{value} { @media @sm { grid-column-end: @value + 1; } }
  .md\:ce@{value} { @media @md { grid-column-end: @value + 1; } }
  .lg\:ce@{value} { @media @lg { grid-column-end: @value + 1; } }
  .rs@{value} { grid-row-start: @value + 1; }
  .sm\:rs@{value} { @media @sm { grid-row-start: @value + 1; } }
  .md\:rs@{value} { @media @md { grid-row-start: @value + 1; } }
  .lg\:rs@{value} { @media @lg { grid-row-start: @value + 1; } }
  .re@{value} { grid-row-end: @value + 1; }
  .sm\:re@{value} { @media @sm { grid-row-end: @value + 1; } }
  .md\:re@{value} { @media @md { grid-row-end: @value + 1; } }
  .lg\:re@{value} { @media @lg { grid-row-end: @value + 1; } }
});

@import (reference) "./common";
@import (reference) "./grid";

#projects {
  padding: 40px 0;
  background: #343538 linear-gradient(174deg, #2186d8 -540px, #343538 300px, #343538 100%) 0 0 no-repeat padding-box;
  color: #fff;

  @media @md {
    padding-bottom: 50px;
  }

  @media @xl {
    padding-top: 105px;
    padding-bottom: 100px;
  }

  .container {
    .flex();
    .column();
  }

  h2 {
    margin-bottom: 22px;
    line-height: 36px;
    color: #adecf7;

    @media @xl {
      font-size: 72px;
      line-height: 93px;
    }
  }

  p.byline {
    font-size: 14px;
    line-height: 18px;

    @media @sm {
      font-size: 18px;
      line-height: 22px;
    }

    @media @xl {
      margin-bottom: 40px;
      font-size: 30px;
      line-height: 39px;
    }
  }

  .project {
    .flex();
    .column();
    .md\:row();
    margin: 22px 0;

    @media @xl {
      margin: 45px 0;
    }

    .project-description {
      .md\:f(4);
      order: 1;

      @media @xl {
        font-size: 24px;
        line-height: 40px;
      }

      h3, h4 {
        margin: 12px 0;
        color: #e2edfa;
      }

      h4 {
        color: #adecf7;
      }

      p + h4 {
        margin-top: 32px;
      }

      a {
        color: #adecf7;
      }

      ul {
        .nolist();

        li::before {
          display: inline-block;
          margin-right: 1em;
          content: ">";
        }
      }
    }

    .project-gallery {
      .md\:f(8);
      order: 0;
      margin: 0 -3px;
      justify-content: space-between;
      align-items: flex-start;

      @media @md {
        margin: 0;
        padding-right: 40px;
      }

      @media @xl {
        padding-right: 54px;
      }

      ul {
        .nolist();
        .grid();
        margin: 0 -5px 15px;

        @media @xl {
          margin: 0 -25px 15px;
        }

        li {
          padding: 0 5px 17px;

          @media @xl {
            padding: 0 25px 50px;
          }

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }

    @media @md {
      &:nth-child(even) .project-gallery {
        order: 2;
        padding-left: 40px;
        padding-right: 0;
      }
    }
  }
}

#btn-load-more-projects {
  .button();
  border-color: #adecf7;
  font-weight: bold;
  color: #adecf7;
  text-transform: uppercase;
}
